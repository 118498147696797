import { Container, Grid, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import authHeaders from "@/lib/authHeaders";
import OnTimeDelivery from "@/pages/home/components/OnTimeDelivery";
import OpenNCRs from "@/pages/home/components/OpenNCRs";
import ProjectsReferences from "@/pages/home/components/ProjectsReferences";
import ReceiptControl from "@/pages/home/components/ReceiptControl";
import Rescheduling from "@/pages/home/components/Rescheduling";
import WeldMaps from "@/pages/home/components/WeldMaps";

const Home = () => {
  const userData = JSON.parse(localStorage.getItem("msToken"));
  const { error, data, isLoading } = useQuery({
    queryKey: ["dashboard-index"],
    queryFn: () =>
      fetch(`${import.meta.env.VITE_VTS_API}/v1/dashboard`, {
        headers: authHeaders(),
      }).then((response) => response.json()),
  });

  return (
    <Container maxWidth="xl" sx={{ pb: 10 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={12}>
          <Typography>
            Welcome{" "}
            <span data-clarity-mask="True">{userData?.account.name}</span>
            <br />
            Today is {data?.today}
          </Typography>
        </Grid>
        <Grid item xs={12} lg={7}>
          <OpenNCRs />
        </Grid>
        <Grid item xs={12} lg={5}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3} lg={6}>
              <ReceiptControl
                itemsInControl={data?.items_in_control}
                qtyInControl={data?.qty_in_control}
                loading={isLoading}
                error={error}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={6}>
              <Rescheduling
                rescheduleOperations={data?.reschedule_operations}
                totalOperations={data?.total_operations}
                rescheduleOrders={data?.reschedule_orders}
                totalProductionOrders={data?.total_production_orders}
                loading={isLoading}
                error={error}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={6}>
              <WeldMaps
                weldsToApprove={data?.welds_to_approve}
                weldMaps={data?.weld_maps}
                loading={isLoading}
                error={error}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={6}>
              <OnTimeDelivery />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={12}>
          <ProjectsReferences />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Home;
